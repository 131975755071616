import React, { ReactNode, useEffect, useState } from "react";
import "./PaymentStatus.scss";

import { BiCheckCircle, BiTime } from "react-icons/bi";

export enum PaymentStatusEnum {
  STATUS_PENDING = "STATUS_PENDING",
  STATUS_SUCCESS = "STATUS_SUCCESS",
  STATUS_EXPIRED = "STATUS_EXPIRED",
  STATUS_INVOICE_EXPIRED = "STATUS_INVOICE_EXPIRED",
  STATUS_TO_PROCESS = "STATUS_TO_PROCESS",
}

function PaymentStatus(props: { status: PaymentStatusEnum | null }) {
  const [paymentStatus, setPaymentStatus] = useState<PaymentStatusEnum | null>(props.status);

  useEffect(() => {
    setPaymentStatus(props.status);
  });

  const reloadPage = (): void => {
    window.location.reload();
  };

  const renderStatus = (): ReactNode => {
    switch (paymentStatus) {
      case PaymentStatusEnum.STATUS_SUCCESS:
        return (
          <>
            <BiCheckCircle className='status status__success' />
            <h2>Payment Received</h2>
          </>
        );
      case PaymentStatusEnum.STATUS_EXPIRED:
        return (
          <>
            <BiTime className='status status__expired' />
            <h2>Payment expired</h2>
            <p className='text'>
              Please{" "}
              <span className='status__reload' onClick={() => reloadPage()}>
                refresh
              </span>{" "}
              the invoice link to pay the invoice, or contact the seller for other inquiries
            </p>
          </>
        );
      case PaymentStatusEnum.STATUS_INVOICE_EXPIRED:
        return (
          <>
            <BiTime className='status status__expired' />
            <h2>Invoice expired</h2>
            <p className='text'>Please generate new one</p>
          </>
        );
      default:
        return;
    }
  };

  return (
    <div className='payment__status'>
      <>{renderStatus()}</>
    </div>
  );
}

export default PaymentStatus;
