import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { InvoiceModel } from "model/Invoice";
import { RootState } from "../../app/store";

const initialInvoiceState = {
  id: null,
  amount: null,
  asset: null,
  title: null,
  description: null,
  expiresAt: null,
  paymentOrders: [],
  payments: [],
  allowedPaymentAssets: [],
} as any;

const invoiceSlice = createSlice({
  name: "invoice",
  initialState: initialInvoiceState,
  reducers: {
    loadInvoiceSuccess(state, action: PayloadAction<InvoiceModel>) {
      return { ...state, ...action.payload };
    },
  },
});

export const selectInvoice = (state: RootState) => state.invoice as InvoiceModel;
export const { loadInvoiceSuccess } = invoiceSlice.actions;
export default invoiceSlice.reducer;
