import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./PaymentPay.scss";
import Counter from "components/counter/Counter";
import Qrcode from "components/qrcode/Qrcode";
import { InvoiceModel, OrderPaymentModel, PaymentModel } from "model/Invoice";
import { MdOutlineContentCopy } from "react-icons/md";
import { BiInfoCircle } from "react-icons/bi";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAppDispatch, useAppSelector } from "app/hooks";
import moment from "moment";
import PaymentStatus, { PaymentStatusEnum } from "../paymentStatus/PaymentStatus";
import { getInvoiceDetails, initializeInvoice } from "../../../../api/api";
import { from } from "rxjs";
import { selectedPaymentAsset } from "../../SelectedPaymentAssetStore";
import Loader from "../loader/Loader";
import { checkInvoiceStatus } from "helpers/invoices";
import ReactTooltip from "react-tooltip";
import BasicTable from "../../../../components/basic-table/basic-table";
import { Stack } from "@mui/material";
import { loadInvoiceSuccess, selectInvoice } from "../../invoiceStore";

function PaymentPay(props: {
  shouldInitialize: boolean;
  currentStatusCode: PaymentStatusEnum | null;
}) {
  const dispatch = useAppDispatch();
  const selectedInvoice = useAppSelector(selectInvoice);
  const [initialized, setInitialized] = useState(false);
  const [paymentOrder, setPaymentOrder] = useState<OrderPaymentModel | null>(null);
  const [showStatusCodeAndDetails, setShowStatusCodeAndDetails] = useState<boolean>(
    props.currentStatusCode === PaymentStatusEnum.STATUS_PENDING,
  );
  const [showStatusCode, setShowStatusCode] = useState<boolean>(!!props.currentStatusCode);
  const [currentStatusCode, setCurrentStatusCode] = useState<PaymentStatusEnum | null>(
    props.currentStatusCode || null,
  );
  const [invoiceStatus, setInvoiceStatus] = useState<PaymentStatusEnum | null>(
    PaymentStatusEnum.STATUS_TO_PROCESS,
  );
  const [time, setTime] = useState<number>(300);
  const [fullTime, setFullTime] = useState<number>(300);
  const selectedAsset = useAppSelector(selectedPaymentAsset);
  const [payments, setPayments] = useState<PaymentModel[]>(selectedInvoice.payments || []);
  const [timer, setTimer] = useState<ReturnType<typeof setInterval> | null>(null);

  useEffect(() => {
    if (props.shouldInitialize) {
      from(
        initializeInvoice(selectedInvoice.id, selectedAsset.asset, selectedAsset.selectedMethod),
      ).subscribe((result) => {
        getLatestInvoiceDetails();
        initData(result.data as OrderPaymentModel);
        setGetInvoiceDetailsInterval();
      });
    } else {
      if (currentStatusCode !== PaymentStatusEnum.STATUS_INVOICE_EXPIRED) {
        initData(selectedInvoice.paymentOrders.at(-1) as OrderPaymentModel);
      }

      if (
        !currentStatusCode ||
        currentStatusCode === PaymentStatusEnum.STATUS_PENDING ||
        !areAllPaymentsFinished
      ) {
        setGetInvoiceDetailsInterval();
      }
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, []);

  const areAllPaymentsFinished = useMemo(() => {
    if (
      !initialized ||
      !selectedInvoice ||
      !selectedInvoice.payments ||
      selectedInvoice.payments.length === 0
    ) {
      return false;
    }

    if (selectedInvoice.payments.length !== payments.length) {
      setPayments(selectedInvoice.payments);
      toast.success("New transaction has been recorded", {
        type: "success",
      });
    }

    return selectedInvoice.payments.every((payment) => payment.isConfirmed || payment.isRejected);
  }, [selectedInvoice.payments]);

  useEffect(() => {
    if (initialized) {
      setInvoiceStatus(checkInvoiceStatus(selectedInvoice));
      setPaymentOrder(selectedInvoice.paymentOrders.at(-1) as OrderPaymentModel);
    }
  }, [selectedInvoice]);

  const initData = (currentPaymentOrder: OrderPaymentModel): void => {
    setPaymentOrder(currentPaymentOrder);
    var currentTime = moment.utc();
    var paymentExpiresAt = moment(currentPaymentOrder?.expiresAt).utc();
    var paymentCreatedAt = moment(currentPaymentOrder?.createdAt).utc();
    setTime(paymentExpiresAt.diff(currentTime, "seconds"));
    setFullTime(paymentExpiresAt.diff(paymentCreatedAt, "seconds"));
    setInitialized(true);
  };

  const getLatestInvoiceDetails = useCallback(() => {
    from(getInvoiceDetails(selectedInvoice.id)).subscribe((res) => {
      dispatch(loadInvoiceSuccess(res.data as InvoiceModel));
    });
  }, []);

  const setGetInvoiceDetailsInterval = useCallback(() => {
    setTimer(setInterval(getLatestInvoiceDetails, 5000));
  }, []);

  useEffect(() => {
    if (initialized) {
      if (invoiceStatus !== PaymentStatusEnum.STATUS_TO_PROCESS) {
        setShowStatusCode(true);
        setCurrentStatusCode(invoiceStatus);

        if (invoiceStatus === PaymentStatusEnum.STATUS_PENDING) {
          setShowStatusCodeAndDetails(true);
        } else {
          setShowStatusCodeAndDetails(false);

          if (invoiceStatus === PaymentStatusEnum.STATUS_SUCCESS) {
            if (areAllPaymentsFinished) {
              if (timer) {
                clearInterval(timer);
              }
            }
          } else {
            if (timer) {
              clearInterval(timer);
            }
          }
        }
      }
    }
  }, [invoiceStatus, initialized, areAllPaymentsFinished]);

  const renderPaymentDetails = useMemo(
    () =>
      initialized && paymentOrder ? (
        <>
          <div className='payment-wrapper'>
            <Counter time={time} fullTime={fullTime} />
            <p className='text'>
              To confirm your order, please send the exact amount of {paymentOrder?.asset} to the
              given address below, or scan the QR code
            </p>
            <p className='text'>Scan QR code to pay</p>
            <Qrcode qrCode={paymentOrder?.qrCode} />
          </div>
          <div className='container container--marginTop'>
            <div className='tooltip'>
              <p className='text text__tooltip text__align--left'>Pay this amount exactly</p>
              <BiInfoCircle
                data-tip='You must pay the exact amount displayed or the payment will cause an error. If you pay too little or too much the payment will fail. Do not send funds directly from exchanges, as this might fail the payment due to delayed time in clearing from an exchange. Always send funds from a wallet and make sure you pay the exact displayed amount'
                className='tooltip__icon'
              />
            </div>
            <div className='container__asset container__asset__copy'>
              <span>
                {paymentOrder?.remainingAmountToPay} | {paymentOrder?.asset}
              </span>
              <div className='copy__icon'>
                <MdOutlineContentCopy
                  className='copy__icon-icon'
                  onClick={() => {
                    navigator.clipboard.writeText(paymentOrder?.remainingAmountToPay?.toString());
                    toast("Amount copied to the clipboard");
                  }}
                />
              </div>
            </div>
            <div className='tooltip'>
              <p className='text text__tooltip text__align--left'>Pay to this address</p>
              <BiInfoCircle
                data-tip='Please pay to this address only and make sure to copy and paste it correctly into your wallet application. If you use a mobile wallet, please scan the QR code. Do not pay directly from an exchange as transactions might fail due to how exchanges sent funds'
                className='tooltip__icon'
              />
            </div>
            <div className='container__asset container__asset__copy'>
              <span>{paymentOrder?.address}</span>
              <div className='copy__icon'>
                <MdOutlineContentCopy
                  className='copy__icon-icon'
                  onClick={() => {
                    navigator.clipboard.writeText(paymentOrder?.address);
                    toast("Address copied to the clipboard");
                  }}
                />
              </div>
            </div>
            <ReactTooltip effect='solid' place='right' className='tooltip__base' />
          </div>
          <a href={paymentOrder?.qrCode} className='btn-link'>
            <button>PAY IN WALLET</button>
          </a>
        </>
      ) : (
        <>
          <Loader></Loader>
        </>
      ),
    [paymentOrder, paymentOrder?.qrCode, paymentOrder?.remainingAmountToPay, paymentOrder?.address],
  );

  const renderPaymentSummary = () => (
    <Stack spacing={4}>
      <PaymentStatus status={currentStatusCode} />
      {selectedInvoice.payments.length > 0 &&
        (currentStatusCode === PaymentStatusEnum.STATUS_SUCCESS ||
          currentStatusCode === PaymentStatusEnum.STATUS_PENDING ||
          currentStatusCode === PaymentStatusEnum.STATUS_EXPIRED) && (
          <BasicTable
            payments={selectedInvoice.payments}
            asset={selectedInvoice.asset}
            paymentOrder={selectedInvoice.paymentOrders.at(-1) as OrderPaymentModel}
          />
        )}
    </Stack>
  );

  if (showStatusCodeAndDetails) {
    return (
      <>
        {renderPaymentDetails} {renderPaymentSummary()}
      </>
    );
  }

  return showStatusCode ? renderPaymentSummary() : renderPaymentDetails;
}

export default PaymentPay;
