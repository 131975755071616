import { InvoiceModel, TransactionNetworkEnum } from "model/Invoice";
import moment from "moment";
import { PaymentStatusEnum } from "pages/invoice/components/paymentStatus/PaymentStatus";
import Decimal from "decimal.js";

export const checkInvoiceStatus = (invoice: InvoiceModel): PaymentStatusEnum => {
  const currentInvoice = invoice;
  const currentInvoicePayments = invoice.payments;
  const currentInvoicePaymentOrder = invoice.paymentOrders.at(-1);
  const remainingAmountToPay = currentInvoicePaymentOrder?.remainingAmountToPay;
  const isAnyPaymentVisible = currentInvoicePayments.length > 0;
  const isAnyPaymentConfirmed =
    isAnyPaymentVisible && currentInvoicePayments.every((val) => val.isConfirmed);

  const isAmountRemainingToPay =
    remainingAmountToPay !== undefined && new Decimal(remainingAmountToPay).gt(0);

  let isOrderNotExpired = null;
  let isInvoiceNotExpired = currentInvoice?.expiresAt
    ? moment.utc().isBefore(moment(currentInvoice?.expiresAt).utc())
    : true;

  if (currentInvoicePaymentOrder) {
    isOrderNotExpired = moment.utc().isBefore(moment(currentInvoicePaymentOrder.expiresAt).utc());
  }

  if (remainingAmountToPay !== undefined && new Decimal(remainingAmountToPay).lte(0)) {
    //success path
    return PaymentStatusEnum.STATUS_SUCCESS;
  }

  //invoice expired path
  if (!isInvoiceNotExpired) {
    return PaymentStatusEnum.STATUS_INVOICE_EXPIRED;
  }

  //pending path
  if (currentInvoicePaymentOrder && isOrderNotExpired) {
    return PaymentStatusEnum.STATUS_PENDING;
  }

  //expired path
  if (currentInvoicePaymentOrder && !isOrderNotExpired) {
    return PaymentStatusEnum.STATUS_EXPIRED;
  }

  return PaymentStatusEnum.STATUS_TO_PROCESS;
};

export const mapTransactionNetworkToTxUrl = (
  transactionNetwork: string,
  value: string,
): string | undefined => {
  switch (transactionNetwork) {
    case TransactionNetworkEnum.BITCOIN:
      return `${process.env.REACT_APP_BLOCK_EXPLORER}${process.env.REACT_APP_BLOCK_EXPLORER_TX}${value}`;
    case TransactionNetworkEnum.ETHEREUM:
      return `${process.env.REACT_APP_BLOCK_EXPLORER_ETH}${process.env.REACT_APP_BLOCK_EXPLORER_TX}${value}`;
    case TransactionNetworkEnum.TRON:
      return `${process.env.REACT_APP_BLOCK_EXPLORER_TRON}${process.env.REACT_APP_BLOCK_EXPLORER_TRANSACTION}${value}`;
    default:
      return;
  }
};

export const mapTransactionNetworkToAddressUrl = (
  transactionNetwork: string,
  value: string,
): string | undefined => {
  switch (transactionNetwork) {
    case TransactionNetworkEnum.BITCOIN:
      return `${process.env.REACT_APP_BLOCK_EXPLORER}${process.env.REACT_APP_BLOCK_EXPLORER_ADDRESS}${value}`;
    case TransactionNetworkEnum.ETHEREUM:
      return `${process.env.REACT_APP_BLOCK_EXPLORER_ETH}${process.env.REACT_APP_BLOCK_EXPLORER_ADDRESS}${value}`;
    case TransactionNetworkEnum.TRON:
      return `${process.env.REACT_APP_BLOCK_EXPLORER_TRON}${process.env.REACT_APP_BLOCK_EXPLORER_ADDRESS}${value}`;
    default:
      return;
  }
};
